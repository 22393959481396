<template>
  <div class="add-new-page-section">
    <p v-if="pages.length" class="section-title">يمكنك اختيار أية صفحة لتفعيل خدمات مجيب عليها</p>
    <p v-else class="section-title">لا يوجد لديك صفحات أخرى</p>
    <loading-spinner v-if="loading" position="absolute"> </loading-spinner>
    <div v-else class="m-container rb">
      <div v-for="page in pages" :key="page.id" class="m-card rb">
        <div class="card-info rb-top">
          <img class="page-image rb" :src="page.picture" />
          <div class="page-name">
            {{ page.name }}
          </div>
        </div>
        <div class="card-action rb-bottom">
          <button class="rounded pointer" @click.prevent="select(page)">اختر</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      pages: []
    };
  },
  methods: {
    select(page) {
      this.loading = true;
      const options = {
        url: `/services/manage/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/registered-pages/${page.id}`,
        method: "post",
        responseType: "json"
      };
      this.$api
        .customRequest(options)
        .then(({ data }) => {
          console.log(data);
          if (data) {
            this.$store.dispatch("page/setPage", data);
          }
          this.loading = false;
          this.$router.push({ name: "Commenter.NewCommenter" });
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  },
  created() {
    const options = {
      url: `/services/manage/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/facebook-pages`,
      method: "get",
      responseType: "json"
    };
    this.loading = true;
    this.$api
      .customRequest(options)
      .then(({ data }) => {
        if (data) {
          console.log(data);
          this.pages = data;
        }
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      });
  }
};
</script>

<style scoped>
* {
  font-family: cairo;
}

.add-new-page-section {
  text-align: center;
}

.rb {
  border-radius: 5px;
}

.rounded {
  border-radius: 50px;
}

.rb-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rb-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.section-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: distribute;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 5;
}

.m-card {
  background-color: white;
  width: 190px;
  height: 250px;
  margin: 25px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease-in-out;
}

.m-card:hover {
  -webkit-transform: scale(1.2, 1.2);
  -webkit-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1, 1);
  transform: scale(1.05, 1.05);
}

.card-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height: 75%;
}

.page-image {
  height: 40%;
  margin: 15%;
  width: auto;
}

.page-name {
  height: 30%;
  font-family: cairo;
  font-size: 17.5px;
}

.card-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 15%;
  margin: 5%;
}

.card-action button {
  border: 0;
  background-color: #77ec18;
  padding-right: 35px;
  padding-left: 35px;
  color: white;
  font-size: 17.5px;
  padding-bottom: 3px;
}

.m-card:hover .card-action button {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

button.pointer {
  cursor: pointer;
}
</style>
